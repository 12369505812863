import { h } from "preact";
import { useEffect, useState } from 'preact/hooks';
import styles from '../style.css'
import { Footer } from "./Footer";
import { getByOre } from "~src/Contracts";
import { SmallClayView } from "./SmallClayView";

export const Ranking = (props) => {
  const { stakingContract } = props;
  const [diamondOres, setDiamondOres] = useState([])
  const [amethystOres, setAmythestOres] = useState([])
  
  useEffect(() => {
    async function loadRankings() {
      setDiamondOres(await getByOre(stakingContract, 8))
      setAmythestOres(await getByOre(stakingContract, 7))
    }

    loadRankings()
  }, []);

  return (
    <div id={styles["connected"]} className={styles["mint-container"]}>
    <b id={styles["mint-header"]}>Diamond CLAYs</b>
    <div style={{ marginTop: '20px', display: 'grid', gridTemplateColumns: 'auto auto auto' }}>
      {diamondOres.map(x => {
        return (<SmallClayView stakingContract={stakingContract} tokenId={x} />)
      })}
    </div>
    <b id={styles["mint-header"]}>Amethyst CLAYs</b>
      <div style={{ marginTop: '20px', display: 'grid', gridTemplateColumns: 'auto auto auto' }}>
        {amethystOres.map(x => {
          return (<SmallClayView stakingContract={stakingContract} tokenId={x} />)
        })}
      </div>
      <Footer />
    </div>)
}