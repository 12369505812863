import Toastify from 'toastify-js'
import retry from 'async-retry'

export function onTxError(message) {
  if (message.indexOf('Cooldown') != -1) {
    message = 'Ability is under cooldown, please wait before using again'
  }

  if (message.indexOf('burn amount') != -1) {
    message = 'Not enough $mud in wallet, please withdraw $mud first'
  }

  if (message.indexOf('Disabled') != -1) {
    message = 'Withdraw has been temporarily disabled'
  }

  return Toastify({
    text: message,
    duration: 20000,
    close: true,
    gravity: "bottom", // `top` or `bottom`
    position: "center", // `left`, `center` or `right`
    stopOnFocus: true, // Prevents dismissing of toast on hover
    style: {
      background: "linear-gradient(to right, #F58828, #FF8875)"
    }
  })
}

export function onTxProgress(message, link?) {
  return Toastify({
    text: message,
    duration: -1,
    close: false,
    onClick: () => {
      window.open(link, '_blank').focus()
    },
    gravity: "bottom", // `top` or `bottom`
    position: "center", // `left`, `center` or `right`
    stopOnFocus: true, // Prevents dismissing of toast on hover
    style: {
      background: "linear-gradient(to right, #48C528, #48CF75)"
    }
  })
}

export function onPerformTransaction(actionCallback, onSuccess, message) {
  return async () => {
    try {
      const transaction = await actionCallback()
      const toast = onTxProgress(`${message}... (Click for Etherscan)`, `https://etherscan.io/tx/${transaction.hash}`)
      toast.showToast()
      await transaction.wait()
      await onSuccess()
      toast.hideToast()
    } catch (e) {
      onTxError(e.message).showToast()
    }
  }
}

export async function getTraits(stakingContract, id) {
  const attributes: string = await stakingContract.renderAttributes(id)
  return JSON.parse(attributes.substring(0, attributes.indexOf(']') + 1))
}

export async function getByOre(stakingContract, oreLevel) {
  const maxSupply = 3333
  const portions = 50
  const concurrency = Math.ceil(maxSupply / portions)
  const section = Array.from(Array(concurrency), (_, x) => x)
  return (await Promise.all(
    section.map(async (x) => {
      //return await retry(async (bail) => {
        return await stakingContract.getByOre(oreLevel,
          x * portions,
          Math.min(x * portions + portions, maxSupply))
      //})
    })
  )).flat()
}

export const nftContractAddress = '0xd8f2eEFEd38F10E9b53952125cFe66d6d1511559';
export const ercContractAddress = '0xee0240888291bb5d2b41b778971a7730a26dcf24';
export const stakingContractAddress = '0xa23901ACf25a46aAbE3CafB1d7B22534B14c89c7';
