// @jsx h
import {h} from 'preact'
import styles from '../../style.css'

export const TabHeader = ({ children }) => {

  return (
    <div className={styles["nav"]} role="tablist">
      {children}
    </div>
  )

}