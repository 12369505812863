export const baseValues = {
  Clay: 10,
  Stone: 20,
  Metal: 30,
  Obsidian: 40
}

export const upgradeCosts = {
  Clay: 50,
  Stone: 100,
  Metal: 250,
  Obsidian: 400
}

export const baseIndex = {
  Clay: 0,
  Stone: 1,
  Metal: 2,
  Obsidian: 3
}

export const oreIndex = {
  None: 0,
  Grass: 1,
  Bronze: 2,
  Jade: 3,
  Gold: 4,
  Ruby: 5,
  Sapphire: 6,
  Amethyst: 7,
  Diamond: 8
}

export const oreValues = {
  None: 1.0,
  Grass: 2.5,
  Bronze: 3.0,
  Jade: 3.5,
  Gold: 4.0,
  Ruby: 1.5,
  Sapphire: 2.0,
  Amethyst: 6.0,
  Diamond: 10.0
}

export const bgColorValues = {
  Forest: 0,
  Mountain: 1,
  River: 2,
  Field: 3,
  Cave: 4,
  Clouds: 5
}

function mode(arr, clause){
  return arr.sort((a,b) =>
        arr.filter(v => clause(v, a)).length
      - arr.filter(v => clause(v, b)).length
  ).pop();
}

export function getLargestBonusId(clayTraits) {
  const mostBgColor = mode(clayTraits, (v,t) => {
    const vBgColor = v.find(x => x.trait_type === 'BgColor')
    const tBgColor = t.find(x => x.trait_type === 'BgColor')
    return vBgColor.value === tBgColor.value
  })

  return bgColorValues[mostBgColor.find(x => x.trait_type === 'BgColor').value]
}