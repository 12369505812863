import { h } from "preact";
import { useState, useEffect } from 'preact/hooks';
import styles from '../style.css'
import { fe, rMud, spinningGif, rTrait, getTotalForTraits, vTrait, generateSVGFromTraits } from "../DisplayHelpers";
import { oreIndex } from "~src/ClayTraits";
import { getTraits } from "~src/Contracts";

export const SmallClayView = (props) => {
  const { tokenId, stakingContract } = props;

  const [clayImage, setClayImage] = useState('')

  useEffect(() => {
    async function loadClay() {
      if (!stakingContract) {
        return
      }

      const traits = await getTraits(stakingContract, tokenId)
      setClayImage(generateSVGFromTraits(traits))
    }

    setClayImage('')
    loadClay()
  }, [tokenId])

  return (
    <div style={{
      width: '150px',
      height: '150px'
    }}>
      <img style={{
        backgroundColor: 'white',
        width: '100px',
        height: '100px'
      }} src={clayImage !== '' ? clayImage : spinningGif}></img><br />
      <div style={{ marginTop: '5px', fontSize: '18px' }}>
        <a target="_blank" href={`https://opensea.io/assets/ethereum/0xd8f2eefed38f10e9b53952125cfe66d6d1511559/${tokenId}`}>{`${tokenId}`}</a>
      </div>
    </div>)
}