import { h } from "preact";
import styles from '../style.css'

export const Header = () => {
  return (
    <div id={styles['header-bar']}>
      <div className={styles.topLogo}>CLAY_</div>
      <a target="_blank" href="https://discord.com/invite/3AecXrDmNd"><img className={styles.socialIcon} src="https://img.icons8.com/discord"></img></a>
      <a target="_blank" href="https://twitter.com/we_are_clay_"><img className={styles.socialIcon} src="https://img.icons8.com/twitter"></img></a>
    </div>)
}