import { h } from "preact";
import { useEffect, useState } from 'preact/hooks';
import styles from '../style.css'
import { Footer } from "./Footer";
import { getByOre, stakingContractAddress } from "~src/Contracts";
import { SmallClayView } from "./SmallClayView";
import * as abiDecoder from 'abi-decoder'
import { ethers } from "ethers";
import stakeABI from '../stake_abi.json'

export const History = (props) => {
  const { stakingContract } = props;
  const [history, setHistory] = useState([])
  
  useEffect(() => {
    async function loadHistory() {
      const etherscanProvider = new ethers.providers.EtherscanProvider();

      const blockHeight = await etherscanProvider.getBlockNumber()

      const txHistory = await etherscanProvider.getHistory(stakingContractAddress, blockHeight - 1000, blockHeight)
      abiDecoder.addABI(stakeABI)
      
      setHistory(txHistory.reverse().map(x => abiDecoder.decodeMethod(x.data))
        .filter(x => {
          return x && ['gemUltimate', 'natureUltimate', 'sap'].includes(x.name)
        }).map(x => { 
          const AbilityNames = {
            'gemUltimate': 'Gem Ultimate', 
            'natureUltimate': 'Nature Ultimate',
            'sap': 'Sap'
          }

          return {
            source: x.params[0].value,
            target: x.params[1].value,
            name: AbilityNames[x.name]
          }
        })
      )
    }

    loadHistory()
  }, []);

  return (
    <div id={styles["connected"]} className={styles["mint-container"]}>
    <b id={styles["mint-header"]}>History</b>
    {history.length > 0 ? 
    (<table style={{ marginTop: '20px', maxWidth: '400px' }}>
      {history.map(x => {
        return (<tr style={{maxWidth: '400px', fontSize: '16px', marginBottom: '20px', border: '1px solid white' }}>
          <td style={{ border: '1px solid white' }}><SmallClayView stakingContract={stakingContract} tokenId={x.source} /></td>
          <td style={{ border: '1px solid white', padding: '10px' }}>Used {x.name} on</td>
          <td style={{ border: '1px solid white', paddingTop: '20px' }}><SmallClayView stakingContract={stakingContract} tokenId={x.target} /></td>
          </tr>)
      })}
    </table>) : (<div style={{ marginTop: '20px' }}>Loading...</div>)}
    <Footer />
    </div>)
}