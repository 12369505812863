import { h } from "preact";
import { ethers } from "ethers"
import { baseIndex, baseValues, oreIndex, oreValues } from "./ClayTraits"
import styles from './style.css'

export const spinningGif = 'https://bafkreibx72uk5yrszibtvbhv67g2b24kz3qfla4wltzkiyptw4tj6u7dq4.ipfs.nftstorage.link/'
export const mudTokenPng = 'https://bafkreihqci6lkp2cdwuxfpqdhebglplp7x7ngfvwugsnfk5x62ljctz4ay.ipfs.nftstorage.link/'

export const fe = bn => {
  const val = ethers.utils.formatEther(bn)
  return (+val).toFixed(2)
}

export const rMud = mud => {
  if(mud === '') {
    return (<span>&nbsp;Loading...</span>)
  }

  return (<span>&nbsp;{mud} $MUD <img style={{ width: '16px', height: '16px' }} src={mudTokenPng}></img></span>)
}

export const getTotalForTraits = (traits) => {
  if(traits === undefined) {
    return 'Loading...'
  }

  const baseTrait = traits.find(x => x.trait_type === 'Base')
  const oreTrait = traits.find(x => x.trait_type === 'Ore')
  const largeOreTrait = traits.find(x => x.trait_type === 'LargeOre')

  const base = baseValues[baseTrait.value]
  const ore = oreValues[oreTrait.value]

  return rMud(base * ore * (largeOreTrait.value === 'Yes' ? 2 : 1))
}

export const vTrait = (traits, name) => {
  if(traits === undefined) {
    return 'Loading...'
  }
  
  return traits.find(x => x.trait_type === name).value
}

export const rTrait = (traits, name) => {
  if(traits === undefined) {
    return 'Loading...'
  }

  const trait = traits.find(x => x.trait_type === name)

  const extra = ()=> {
    if(trait.trait_type === 'Base') {
      return <span>{rMud(baseValues[trait.value])}&nbsp;(BASE per day)</span>
    } else if(trait.trait_type === 'Ore') {
      return <span>&nbsp;({oreValues[trait.value]}x&nbsp;ORE multiplier)</span>
    } else if(trait.trait_type === 'LargeOre') {
      return <span>&nbsp;({trait.value === 'Yes' ? 2.0 : 1.0}x&nbsp;LARGE ORE multiplier)</span>
    }

    return ''
  }

  return (
  <span>
    <span className={styles[trait.value.toLowerCase()]}>{trait.value}</span>
    <span>&nbsp;{extra()}</span>
  </span>
  )
}

export function generateSVGFromTraits(traits) {
  const svgPrefix = 'data:image/svg+xml;base64,';

  const baseTraitIndex = baseIndex[vTrait(traits, 'Base')];
  const oreTraitIndex = oreIndex[vTrait(traits, 'Ore')];
  const largeOreTraitIndex = vTrait(traits, 'LargeOre') === 'Yes' ? 1 : 0;

  const baseColors = [
      "rgb(130,101,82)",
      "rgb(77,83,87)",
      "rgb(121,126,139)",
      "black"
  ];

  const baseColor = baseColors[baseTraitIndex];

  const oreColors = [
      baseColor,
      "rgb(70.9,195.5,82.8)",
      "rgb(181,129,68)",
      "rgb(170,218,170)",
      "rgb(195,155,65)",
      "rgb(255,0,30)",
      "rgb(39,100,255)",
      "rgb(148,42,235)",
      "white"
  ];

  const oreColor = oreColors[oreTraitIndex];

  const oreSize = [
    '<path d="M196.947 188.849l28.058-28.744 11.648 1.592-37.16 38.578z" fill="',
    '<path d="M197.01 188.792l28.057-28.743 23.784 3.167-47.196 47.095z" fill="'
  ];
  
  const baseElement = 
    '<path d="M322.654 173.053L189.77 155.342l26.702 123.092-101.336 45.107 265.728 21.117-86.728-62.137z" fill="' +
    baseColor +
    '"/>';

  const oreElement = 
    oreSize[largeOreTraitIndex] +
    oreColor +
    '"/>';

  const svgString = 
          '<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" width="500" height="500"><text font-family="sans-serif" font-size="56" font-weight="200" letter-spacing="-1.12" transform="translate(398.63 455.71)"><tspan x="-84.04" y="18.06">C</tspan><tspan x="-46.52" y="18.06">L</tspan><tspan x="-14.21" y="18.06">A</tspan><tspan x="19.9" y="18.06">Y</tspan><tspan x="55.29" y="18.06">_</tspan></text><text font-family="sans-serif" font-size="15" font-weight="300" transform="translate(248 19)"><tspan x="-66.87" y="2.28">Open to see 3D view</tspan></text>' +
          baseElement +
          oreElement +
          '</svg>'

  return svgPrefix + btoa(svgString);
}
