// @jsx h
import { h } from 'preact';

export const TabContent = ({ children }) => {
  return (
    <div class="tab-content">
      {children}
    </div>
  );
};
