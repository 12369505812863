import { h } from "preact";
import { useState } from 'preact/hooks';
import styles from '../style.css'
import { vTrait } from "../DisplayHelpers";
import { oreIndex, upgradeCosts } from "../ClayTraits";
import { onPerformTransaction } from "~src/Contracts";
import { ClayView } from "./ClayView";
import { ActionButton } from "./ActionButton";
import { Footer } from "./Footer";

export const Upgrades = (props) => {
  const { tokens, stakingContract, nftContract, clayTraits, updateClayTraits, mudBalance } = props;

  const [clayId, setClayId] = useState(0)
  const [upgradeChoice, setUpgradeChoice] = useState(0)
  const [clayMudBalance, setClayMudBalance] = useState('')

  if (!clayTraits[clayId]) {
    return (<div style={{ marginTop: '64px' }}>Loading...</div>)
  }

  const getOreUpgradeCost = () => {
    let cost = upgradeCosts[vTrait(clayTraits[clayId], 'Base')] * 2
    if (oreIndex[vTrait(clayTraits[clayId], 'Ore')] === 0) {
      if (upgradeChoice === 1) {
        cost *= 3
      }
    } else {
      cost *= ((oreIndex[vTrait(clayTraits[clayId], 'Ore')] - 1) % 4 + 2)
    }

    return cost
  }

  return (
    <div id={styles["connected"]} className={styles["mint-container"]}>
      <ClayView
        clayId={clayId}
        setClayId={setClayId}
        clayMudBalance={clayMudBalance}
        setClayMudBalance={setClayMudBalance}
        tokens={tokens}
        stakingContract={stakingContract}
        nftContract={nftContract}
        clayTraits={clayTraits}
      />

      {oreIndex[vTrait(clayTraits[clayId], 'Ore')] === 0 ?
        (<div className={styles["mint-container"]}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <ActionButton
              onClick={() => { setUpgradeChoice(0) }}
              title={'NATURE PATH'}
              disabled={upgradeChoice === 0} />
            <ActionButton
              onClick={() => { setUpgradeChoice(1) }}
              title={'GEM PATH'}
              disabled={upgradeChoice === 1} />
          </div>
          <ActionButton
            onClick={onPerformTransaction(async () => {
              return await stakingContract.chooseOre(tokens[clayId], upgradeChoice)
            }, async () => {
              updateClayTraits(clayId)
            }, 'Choosing Ore')
            }
            title={`CHOOSE ORE ${upgradeChoice ? 'GEM' : 'NATURE'} (${getOreUpgradeCost()} $MUD)`} />
          <div className={styles["info-wrapper"]}>
            <div className={styles["info-text"]}>To upgrade a plain (No Ore) CLAY, select a path first (Gem path has an additional cost only for the first upgrade)</div>
          </div>
        </div>) :
        (oreIndex[vTrait(clayTraits[clayId], 'Ore')] !== 4 && oreIndex[vTrait(clayTraits[clayId], 'Ore')] !== 8 ? (<div>
          <ActionButton
            onClick={onPerformTransaction(async () => {
              return await stakingContract.upgradeOre(tokens[clayId])
            }, async () => {
              updateClayTraits(clayId)
            }, 'Upgrading Ore')
            }
            title={`UPGRADE ORE (${getOreUpgradeCost()} $MUD)`}
            disabled={mudBalance < getOreUpgradeCost()} />
          <div className={styles["info-wrapper"]}>
            <div className={styles["info-text"]}>Upgrade ORE on the same path (Nature or Gem) to generate more $MUD</div>
          </div>
        </div>) : (<div></div>))}

      {vTrait(clayTraits[clayId], 'HasEyes') === 'Yes' ? (<div></div>) :
        (
          <div>
            <ActionButton
              onClick={onPerformTransaction(async () => {
                return await stakingContract.purchaseEyes(tokens[clayId])
              }, async () => {
                updateClayTraits(clayId)
              }, 'Purchasing eyes')
              }
              title={`PURCHASE EYES (200 $MUD)`}
              disabled={mudBalance < 200} />
            <div className={styles["info-wrapper"]}>
              <div className={styles["info-text"]}>Allows skipping cooldown for one ability</div>
            </div>

          </div>)
      }


      {vTrait(clayTraits[clayId], 'HasMouth') === 'Yes' ? (<div></div>) :
        (
          <div>
            <ActionButton
              onClick={onPerformTransaction(async () => {
                return await stakingContract.purchaseMouth(tokens[clayId])
              }, async () => {
                updateClayTraits(clayId)
              }, 'Purchasing mouth')
              }
              title={`PURCHASE MOUTH (25 $MUD)`}
              disabled={mudBalance < 25} />
            <div className={styles["info-wrapper"]}>
              <div className={styles["info-text"]}>Prevents attacks from other CLAYs 1 time</div>
            </div>
          </div>)}

      {vTrait(clayTraits[clayId], 'LargeOre') === 'Yes' ? (<div></div>) :
        (<div>
          <ActionButton
            onClick={onPerformTransaction(async () => {
              return await stakingContract.purchaseLargeOre(tokens[clayId])
            }, async () => {
              updateClayTraits(clayId)
            }, 'Purchasing large ore')
            }
            title={`PURCHASE LARGE ORE (1000 $MUD)`}
            disabled={mudBalance < 1000} />
          <div className={styles["info-wrapper"]}>
            <div className={styles["info-text"]}>Doubles $MUD production</div>
          </div>
        </div>)}
      <Footer />
    </div>)
}