import { h } from "preact";
import styles from '../style.css'

export const ActionButton = (props) => {
  const { title, onClick, disabled } = props;
  const classNames = styles["button"] + (disabled ? ' ' + styles["disabled"] : '')
  return (
      <div className={classNames} id={styles["connect-button"]} style={{ marginTop: '16px' }} 
        onClick={disabled ? ()=>{} : onClick}>
        {title}
      </div>)
}