import { h } from "preact";
import { useState } from 'preact/hooks';
import styles from '../style.css'
import { fe, rMud } from "../DisplayHelpers";
import { getLargestBonusId } from "../ClayTraits";
import { onPerformTransaction } from "~src/Contracts";
import { ClayView } from "./ClayView";
import { ActionButton } from "./ActionButton";
import { Footer } from "./Footer";

export const Gallery = (props) => {
  const { setMudBalance, tokens, address, stakingContract, nftContract, ercContract,
    totalMudWithdraw, totalMudWithdrawWithBonus, clayTraits } = props;

  const [clayId, setClayId] = useState(0)
  const [clayMudBalance, setClayMudBalance] = useState('')

  return (
    <div id={styles["connected"]} className={styles["mint-container"]}>
      {parseFloat(totalMudWithdraw) > parseFloat(totalMudWithdrawWithBonus) ?
        (<div>
          <ActionButton
            onClick={onPerformTransaction(async () => {
              return await stakingContract.withdrawAll(tokens)
            }, async () => {
              setMudBalance(fe(await ercContract.balanceOf(address)))
              setClayMudBalance(fe(await stakingContract.getWithdrawAmount(tokens[clayId])))
            }, 'Withdrawing')
            }
            title={'WITHDRAW ALL $MUD'} />
          <div className={styles["info-wrapper"]}>
            <div className={styles["info-text"]}>Total to WITHDRAW from all CLAYS: {rMud(totalMudWithdraw)}</div>
          </div></div>) : (<div>
            <ActionButton
              onClick={onPerformTransaction(async () => {
                return await stakingContract.withdrawAllWithBonus(tokens, getLargestBonusId(clayTraits))
              }, async () => {
                setMudBalance(fe(await ercContract.balanceOf(address)))
                setClayMudBalance(fe(await stakingContract.getWithdrawAmount(tokens[clayId])))
              }, 'Withdrawing')
              }
              title={'WITHDRAW ALL $MUD'} />

            <div className={styles["info-wrapper"]}>
              <div className={styles["info-text"]}>Total to WITHDRAW with env bonus: {rMud(totalMudWithdrawWithBonus)}</div>
            </div></div>)}
      <div style={{ marginTop: '32px' }}><u><b id={styles["mint-header"]}>Your CLAYs</b></u></div>
      <ClayView
        clayId={clayId}
        setClayId={setClayId}
        clayMudBalance={clayMudBalance}
        setClayMudBalance={setClayMudBalance}
        tokens={tokens}
        stakingContract={stakingContract}
        nftContract={nftContract}
        clayTraits={clayTraits}
      />

      <ActionButton
        onClick={onPerformTransaction(async () => {
          return await stakingContract.withdraw(tokens[clayId])
        }, async () => {
          setMudBalance(fe(await ercContract.balanceOf(address)))
          setClayMudBalance(fe(await stakingContract.getWithdrawAmount(tokens[clayId])))
        }, 'Withdrawing')
        }
        title={'WITHDRAW (THIS CLAY)'} />
      <Footer />
    </div>)
}