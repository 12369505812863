import { h } from "preact";
import { useState, useEffect } from 'preact/hooks';
import styles from '../style.css'
import { fe, rMud, spinningGif, rTrait, getTotalForTraits, vTrait, generateSVGFromTraits } from "../DisplayHelpers";
import { oreIndex } from "~src/ClayTraits";

export const ClayView = (props) => {
  const { clayId, setClayId, clayMudBalance, setClayMudBalance, tokens, stakingContract, nftContract, clayTraits } = props;
  
  const [clayHTML, setClayHTML] = useState('')
  const [clayImage, setClayImage] = useState('')
  const [isImage, setIsImage] = useState(0)

  useEffect(() => {
    if(window.localStorage) {
      if(parseInt(window.localStorage.getItem('CLAY_USE_IMAGE'))) {
        setIsImage(1)
      }
    }
  }, [])
  
  useEffect(() => {
    async function loadClay() {
      if(!nftContract) {
        return
      }

      if(clayId < tokens.length && clayTraits[clayId]) {    
        if(isImage) {
          setClayImage(generateSVGFromTraits(clayTraits[clayId]))        
        } else {
          const html = await nftContract.getOnChainAnimationURI(tokens[clayId])
          setClayHTML(html)
        }
      }
    }
    
    if(window.localStorage) {
      window.localStorage.setItem('CLAY_USE_IMAGE', '' + isImage)
    }

    setClayHTML('')
    setClayImage('')       
    loadClay()
  }, [tokens, clayId, isImage, clayTraits]);

  useEffect(() => {
    async function loadClay() {
      if(!stakingContract) {
        return
      }

      if(clayId < tokens.length) {    
        const clayBalance = fe(await stakingContract.getWithdrawAmount(tokens[clayId]))
        setClayMudBalance(clayBalance)
      }
    }

    setClayMudBalance('')
    loadClay()
  }, [tokens, clayId]);

  return (
    <div className={styles["mint-container"]}>  
      {tokens[clayId] ? 
      <b id={styles["mint-header"]}>{`CLAY #${tokens[clayId].toString()}`}</b> : <div />}
      {isImage || clayHTML === '' ? <img id={styles["clay-view"]} style={{ backgroundColor: 'white' }} src={clayImage === '' ? spinningGif : clayImage}></img> :
      <iframe id={styles["clay-view"]} src={clayHTML}></iframe>}
      
      <div className={styles["button"]} id={styles["connect-button"]} style={{ marginTop: '16px' }} onClick={
          () => setIsImage(isImage ? 0 : 1)
        }>
        {isImage ? 'Toggle 3D View' : 'Toggle Image'}
      </div>                  
      <div id={styles["button-container"]}>
        <div className={`${styles["button"]} ${styles["arrow"]}`} onClick={() => {
          setClayId(() => {
            return clayId > 0 ? clayId - 1 : tokens.length - 1
          })}}>⬅</div><span className={styles["info-text"]}>Select CLAY</span>
        <div className={`${styles["button"]} ${styles["arrow"]}`} onClick={() => {setClayId(() => {
          return (clayId + 1) % tokens.length
        })}}>➡</div>
      </div>
      <div className={styles["info-wrapper"]} style={{ marginTop: '16px' }}>
        <div className={styles["info-text"]}>This CLAY holds {rMud(clayMudBalance)}</div><br/>
        <div className={styles["info-text"]}>Base:&nbsp;{rTrait(clayTraits[clayId], 'Base')}</div><br/>
        <div className={styles["info-text"]}>Ore Type:&nbsp;{rTrait(clayTraits[clayId], 'Ore')}</div><br/>
        <div className={styles["info-text"]}>Path:&nbsp;{oreIndex[vTrait(clayTraits[clayId], 'Ore')] > 0 ? (
          oreIndex[vTrait(clayTraits[clayId], 'Ore')] > 4 ?
          ('Gem') : ('Nature')
        ) : ('None')}</div><br/>
        <div className={styles["info-text"]}>Is Large Ore:&nbsp;{rTrait(clayTraits[clayId], 'LargeOre')}</div><br/>
        <div className={styles["info-text"]}>Has Eyes:&nbsp;{rTrait(clayTraits[clayId], 'HasEyes')}</div><br/>
        <div className={styles["info-text"]}>Has Mouth:&nbsp;{rTrait(clayTraits[clayId], 'HasMouth')}</div><br/>
        <div className={styles["info-text"]}>Total $MUD per day:&nbsp;{getTotalForTraits(clayTraits[clayId])}</div><br/>
      </div>
    </div>)
}