// @jsx h
import { h } from 'preact';
import styles from '../../style.css'


export const TabHeaderItem = ({ children, active, onSelect = () => {} }) => {
  const cssClass = active ? '' : styles['inactive-tab'];

  return (
    <div role="presentation" onClick={handleClick}>
      <a role="tab" data-toggle="tab" className={`${styles['tab']} ${cssClass}`} >
        {children}
      </a>
    </div>
  );

  function handleClick(): void {
    onSelect();
  }
};
