import { h } from "preact";
import { useEffect, useState } from 'preact/hooks';
import styles from '../style.css'
import { fe, vTrait } from "../DisplayHelpers";
import { oreIndex } from "../ClayTraits";
import { ClayView } from "./ClayView";
import { ActionButton } from "./ActionButton";
import { Footer } from "./Footer";
import { getTraits, onPerformTransaction } from "~src/Contracts";
import { TargetSelect } from "./TargetSelect";

export const Abilities = (props) => {
  const { tokens, stakingContract, nftContract, clayTraits, setMudBalance, ercContract, address, updateClayTraits } = props;

  const [clayId, setClayId] = useState(0)
  const [clayMudBalance, setClayMudBalance] = useState('')
  const [abilityChoice, setAbilityChoice] = useState('')
  const [targetChoice, setTargetChoice] = useState(-1)
  const [targetTraits, setTargetTraits] = useState(undefined)
  
  useEffect(() => {
    setAbilityChoice('')
    setTargetChoice(-1)
  }, [clayId])

  useEffect(() => {
    const updateTargetTraits = async () => {
      if(targetChoice >= 0) {
        setTargetTraits(await getTraits(stakingContract, targetChoice))
      }
    }

    updateTargetTraits()
  }, [targetChoice])

  const AbilityDefinitions = {
    'sap': {
      requiresTarget: true,
      execute: onPerformTransaction(async () => {
        return await stakingContract.sap(tokens[clayId], targetChoice)
      }, async () => {
        setMudBalance(fe(await ercContract.balanceOf(address)))
      }, 'Ability: Sap'),
      name: 'Sap',
      checkTarget: () => {
        const targetMud = parseFloat(vTrait(targetTraits, 'Mud'))

        return targetMud > 2500 ? {
          valid: true,
          message: 'Target CLAY has sufficient $MUD for sap.'
        } : {
          valid: false,
          message: 'Target invalid. Target CLAY has insufficient $MUD for sap.'
        }
      }
    },
    'selfDestruct': {
      requiresTarget: false,
      execute: onPerformTransaction(async () => {
        return await stakingContract.selfDestruct(tokens[clayId])
      }, async () => {
        await updateClayTraits(clayId)
        setMudBalance(fe(await ercContract.balanceOf(address)))
        setClayMudBalance(fe(await stakingContract.getWithdrawAmount(tokens[clayId])))
      }, 'Ability: Self Destruct'),
      name: 'Self Destruct',
      checkTarget: () => {
        return {
          valid: true,
          message: 'Confirm to Self Destruct.'
        }
      }
    },
    'natureUltimate': {
      requiresTarget: true,
      execute: onPerformTransaction(async () => {
        return await stakingContract.natureUltimate(tokens[clayId], targetChoice)
      }, async () => {
        setMudBalance(fe(await ercContract.balanceOf(address)))
        setClayMudBalance(fe(await stakingContract.getWithdrawAmount(tokens[clayId])))
        await updateClayTraits(clayId)
      }, 'Ability: Nature Ultimate'),
      name: 'Nature Ultimate',
      checkTarget: () => {
        const myOre = oreIndex[vTrait(clayTraits[clayId], 'Ore')]
        const targetOre = oreIndex[vTrait(targetTraits, 'Ore')]
        const myOreLevelAdjusted = (myOre - 1) % 4;
        const targetOreLevelAdjusted = (targetOre - 1) % 4;
        const valid = (myOreLevelAdjusted + 1) === targetOreLevelAdjusted

        const warning = vTrait(targetTraits, 'HasMouth') === 'Yes' ?
          'WARNING: Target has Mouth, this attack will be repelled the first time' : '';

        return {
          valid: valid,
          message: valid ? `Target is valid (${vTrait(targetTraits, 'Ore')} ore)\n${warning}` : 'Target is invalid, must be exactly 1 ore level higher'
        }
      }
    },
    'manifest': {
      requiresTarget: false,
      execute: onPerformTransaction(async () => {
        return await stakingContract.manifest(tokens[clayId])
      }, async () => {
        await updateClayTraits(clayId)
      }, 'Ability: Manifest'),
      name: 'Manifest',
      checkTarget: () => {
        return {
          valid: true,
          message: 'Confirm to Manifest.'
        }
      }
    },
    'coalesce': {
      requiresTarget: false,
      execute: onPerformTransaction(async () => {
        return await stakingContract.coalesce(tokens[clayId], tokens)
      }, async () => {
        setMudBalance(fe(await ercContract.balanceOf(address)))
        setClayMudBalance(fe(await stakingContract.getWithdrawAmount(tokens[clayId])))
      }, 'Ability: Coalesce'),
      name: 'Coalesce',
      checkTarget: () => {
        return {
          valid: true,
          message: 'Confirm to Coalesce.'
        }
      }
    },
    'gemUltimate': {
      requiresTarget: true,
      execute: onPerformTransaction(async () => {
        return await stakingContract.gemUltimate(tokens[clayId], targetChoice)
      }, async () => {
        setMudBalance(fe(await ercContract.balanceOf(address)))
        await updateClayTraits(clayId)
      }, 'Ability: Gem Ultimate'),
      name: 'Gem Ultimate',
      checkTarget: () => {
        const myOre = oreIndex[vTrait(clayTraits[clayId], 'Ore')]
        const targetOre = oreIndex[vTrait(targetTraits, 'Ore')]
        
        const myOreLevelAdjusted = (myOre - 1) % 4;
        const targetOreLevelAdjusted = (targetOre - 1) % 4;
        const valid = myOreLevelAdjusted >= targetOreLevelAdjusted && targetOre > 0 
          && targetOreLevelAdjusted > 0

        const warning = vTrait(targetTraits, 'HasMouth') === 'Yes' ?
          'WARNING: Target has Mouth, this attack will be repelled the first time' : '';

        return {
          valid: valid,
          message: valid ? `Target is valid (${vTrait(targetTraits, 'Ore')} ore)\n${warning}` : 'Target is invalid, must be same ore level or lower and above first level of ore'
        }
      }
    }
  }

  if(!clayTraits[clayId]) {
    return (<div style={{marginTop: '64px'}}>Loading...</div>)
  }

  return (
    <div id={styles["connected"]} className={styles["mint-container"]}>
      <b id={styles["mint-header"]}>1. Select CLAY</b>
      <ClayView
        clayId={clayId}
        setClayId={setClayId}
        clayMudBalance={clayMudBalance}
        setClayMudBalance={setClayMudBalance}
        tokens={tokens}
        stakingContract={stakingContract}
        nftContract={nftContract}
        clayTraits={clayTraits}
      />

      <b id={styles["mint-header"]} style={{ marginBottom: '20px' }}>2. Select Ability</b>
      {oreIndex[vTrait(clayTraits[clayId], 'Ore')] === 0 ?
        (<div>Plain (No Ore) CLAYs have no abilities, upgrade in UPGRADES to obtain abilities.</div>) : (<div></div>)}

      {oreIndex[vTrait(clayTraits[clayId], 'Ore')] > 0 && oreIndex[vTrait(clayTraits[clayId], 'Ore')] < 5 ?
        ( <div>
            <ActionButton onClick={() => {setAbilityChoice('sap')}} title={`Sap (Free)`} disabled={abilityChoice === 'sap'}/>
            <div className={styles["info-wrapper"]}>
              <div className={styles["info-text"]}>Steal 2500 $MUD from other clays (Free) (1 day cooldown) (Ignored by mouth)</div>
            </div>
            <ActionButton onClick={() => {setAbilityChoice('selfDestruct')}} title={`Self Destruct (Free)`} disabled={abilityChoice === 'selfDestruct'}/>
            <div className={styles["info-wrapper"]}>
              <div className={styles["info-text"]}>Downgrade ORE level (Can go back down to no ore) and refund 75% of the upgrade cost. (Free) (1 day cooldown)</div>
            </div>
            <ActionButton onClick={() => {setAbilityChoice('natureUltimate')}} title={`Nature Ultimate (50 $MUD)`} disabled={abilityChoice === 'natureUltimate'}/>
            <div className={styles["info-wrapper"]}>
              <div className={styles["info-text"]}>Swap ore with another 1 level higher than you (3 day cooldown) (Mouth prevents this but gets used up)</div>
            </div>
          </div>) : (<div></div>)}

      {oreIndex[vTrait(clayTraits[clayId], 'Ore')] > 4 ?
        ( <div>
            <ActionButton onClick={() => {setAbilityChoice('manifest')}} title={`Manifest (Free)`} disabled={abilityChoice === 'manifest'}/>
            <div className={styles["info-wrapper"]}>
              <div className={styles["info-text"]}>Obtain mouth (Free) (7 day cooldown)</div>
            </div>
            <ActionButton onClick={() => {setAbilityChoice('coalesce')}} title={`Coalesce (20 $MUD)`} disabled={abilityChoice === 'coalesce'}/>
            <div className={styles["info-wrapper"]}>
              <div className={styles["info-text"]}>Collect all $MUD stored in all other owned CLAYs and add 10% (1 day cooldown)</div>
            </div>
            <ActionButton onClick={() => {setAbilityChoice('gemUltimate')}} title={`Gem Ultimate (Free)`} disabled={abilityChoice === 'gemUltimate'}/>
            <div className={styles["info-wrapper"]}>
              <div className={styles["info-text"]}>Downgrade 1 CLAY with same level or lower than you and earn 5000MU (Free) (3 day cooldown) (Mouth prevents this but gets used up)</div>
            </div>
          </div>) : (<div></div>)}
      {abilityChoice !== '' ?
      (<div style={{ marginBottom: '20px', marginTop: '20px' }}>
      <b id={styles["mint-header"]} style={{ marginBottom: '40px', marginTop: '40px' }}>3. Select Target</b><br />
      {AbilityDefinitions[abilityChoice].requiresTarget ? (

      <div className={styles["mint-container"]}>
      <TargetSelect
        stakingContract={stakingContract}
        setTargetChoice={setTargetChoice}
      /><br />
      </div>) : (<div style={{ marginBottom: '40px', marginTop: '40px' }}>No target required</div>)}

      <br /><b id={styles["mint-header"]} style={{ marginBottom: '20px' }}>4. Confirm</b><br />
      <div className={styles["mint-container"]} style={{ marginTop: '20px' }}>
        <div className={styles["info-text"]}>{AbilityDefinitions[abilityChoice].checkTarget().message}</div>
      </div>
      <ActionButton onClick={AbilityDefinitions[abilityChoice].execute} 
        title={`Confirm: ${AbilityDefinitions[abilityChoice].name} ${targetChoice >= 0 && AbilityDefinitions[abilityChoice].requiresTarget ? `Target: #${targetChoice}` : ''}`}
        disabled={!AbilityDefinitions[abilityChoice].checkTarget().valid}/>
      </div>) : (<div></div>)}

      <Footer />
    </div>)
}