// @jsx h
import { h } from 'preact';

import { TabHeader } from './TabsHeader';
import { TabContent } from './TabContent';
import { TabHeaderItem } from './TabHeaderItem';

export const Tabs = ({
  children,
  activeTab = 0,
  renderInactive,
  onChangeTab,
}) => {
  const { header, body } = tabsToRender(
    children,
    activeTab,
    tabId => () => onChangeTab(tabId),
    renderInactive,
  );
  return (
    <div>
      <TabHeader>
        {header}
      </TabHeader>
      <TabContent>
        {body}
      </TabContent>
    </div>
  );
};

function tabsToRender(tabs, activeTab, getHandleChangeTab, lazyLoad) {
  const initialValue = {
    header: [],
    body: [],
  };

  if(!tabs.reduce) {
    tabs = [tabs]
  }

  return tabs.reduce((acc, tab, idx) => {
    const isActive = activeTab === idx;
    const renderContent = isActive || lazyLoad;
    const { title } = tab.props;

    const HeaderItem = (
      <TabHeaderItem
        key={idx}
        active={isActive}
        onSelect={getHandleChangeTab(idx)}
      >
        {title}
      </TabHeaderItem>
    );

    const ContentItem = renderContent ? tab : null;

    tab.props.active = isActive;
    tab.key = idx;

    return {
      header: [...acc.header, HeaderItem],
      body: [...acc.body, ContentItem],
    };
  }, initialValue);
}

function renderTabs(tabs, activeTab, renderInactive) {
  return tabs.map((tab, idx) => {
    const isActive = activeTab === idx;

    tab.attributes.active = isActive;

    return isActive || renderInactive ? tab : null;
  });
}

function renderHeaderItems(tabs, activeTab) {
  return tabs.map((tab, idx) => {
    const isActive = activeTab === idx;
    const title = tab.attributes.title;

    return (
      <TabHeaderItem
        key={idx}
        active={isActive}
        onSelect={getHandleChangeTab(idx)}
      >
        {title}
      </TabHeaderItem>
    );
  });
}

function getHandleChangeTab(tab, onChangeTab = () => {}) {
  return () => onChangeTab(tab);
}
