// @jsx h
import { h } from 'preact';

export const Tab = ({ children, active, title }) => {
  return (
    <div role="tabpanel" className={`tab-pane`}>
      {children}
    </div>
  );
};
