import { h } from "preact";
import { useState, useEffect } from 'preact/hooks';
import { generateSVGFromTraits, spinningGif } from "../DisplayHelpers";
import Flicking, { WillChangeEvent } from "@egjs/preact-flicking";
import { Arrow } from "@egjs/flicking-plugins";
import flickingStyles from "@egjs/flicking-plugins/dist/arrow.css";
import { ViewportSlot } from "@egjs/preact-flicking";
import { ActionButton } from "./ActionButton";
import { getTraits } from "~src/Contracts";

const randomList = (amount, max) => {
  return [...Array(amount)].map(x => {
    return ~~(Math.random() * max)
  })
}

let globalPanels = []

export const TargetSelect = (props) => {
  const { setTargetChoice, stakingContract } = props;
  
  const [clayImages, setClayImages] = useState([])
  const [panels, setPanels] = useState(randomList(15, 3333));
  const _plugins = [new Arrow()];
  
  useEffect(() => {
    async function loadImages() {
      let images = await Promise.all(
        panels.map(async x => {          
          const traits = await getTraits(stakingContract, x)
          return generateSVGFromTraits(traits)
        })
      )

      setClayImages([...images])
    }

    loadImages()
    setTargetChoice(panels[0])
    globalPanels = panels;
  }, [panels]);

  const willChangeEvent = (e: WillChangeEvent) => {
    setTargetChoice(globalPanels[e.index])
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <div>Random:</div>
      <ActionButton onClick={() => {
        setPanels(randomList(15, 3333))
      }} 
        title="Refresh" />
    <Flicking
    style={{ width: '400px', marginTop: '20px' }}
    renderOnlyVisible={true}
    plugins={_plugins}
    onWillChange = {willChangeEvent}>
      {panels.map((x, index) => <div className="flicking-panel" 
      style={{ 
        width: '150px',
        height: '150px'
      }}      
      key={x}>
        <img style={{ backgroundColor: 'white', 
        width: '100px',
        height: '100px' }} src={clayImages[index] ? clayImages[index] : spinningGif }></img><br />
        <div style={{ marginTop: '5px', fontSize: '18px' }}>
          <a target="_blank" href={`https://opensea.io/assets/ethereum/0xd8f2eefed38f10e9b53952125cfe66d6d1511559/${x}`}>{`${x}`}</a>
        </div>
      </div>)}
      
      <ViewportSlot>
        <span className={`flicking-arrow-prev ${flickingStyles["flicking-arrow-prev"]}`}></span>
        <span className={`flicking-arrow-next ${flickingStyles["flicking-arrow-next"]}`}></span>
      </ViewportSlot>
    </Flicking>
    <div>or</div><br/>
    <div>Manually Enter ID: </div>
    <input type="text" onInput={e => {
      const { value } = e.target;
      try {
        let parsedValue = parseInt(value)
        parsedValue = Math.min(parsedValue, 3332)
        parsedValue = Math.max(parsedValue, 0)
        if(isNaN(parsedValue)) {
          parsedValue = 0
        }
        e.target.value = parsedValue
        setTargetChoice(parsedValue)
      } catch(e) {}
    }}/>
    </div>)
}