import { h } from "preact";
import styles from '../style.css'
import { mudTokenPng } from "../DisplayHelpers";

export const Footer = () => {
  return (
    <div className={styles["info-wrapper"]} style={{ marginTop: '20px' }}>
      <div className={styles["info-text"]}><a href="https://ipfs.io/ipfs/bafybeicdpnfzuf7d3nnlndbckruxr54gcsx4dqrbcuoz644ua27qdntkq4"><img style={{ width: '16px', height: '16px' }} src={mudTokenPng}></img> CLAY WARS V1 Lite Paper</a></div><br />
      <div className={styles["info-text"]}><a href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xEe0240888291bb5D2b41B778971A7730A26DcF24&chain=mainnet"><img style={{ width: '16px', height: '16px' }} src={mudTokenPng}></img> $MUD Uniswap</a></div><br />
      <div className={styles["info-text"]}><a href="https://www.dextools.io/app/ether/pair-explorer/0x13ec351799a349dc50ea7d988256e1ce547471f0"><img style={{ width: '16px', height: '16px' }} src={mudTokenPng}></img> $MUD DEXTOOLS</a></div><br />
      <div className={styles["info-text"]}><a href="https://app.uniswap.org/#/add/ETH/0xEe0240888291bb5D2b41B778971A7730A26DcF24/10000?chain=mainnet"><img style={{ width: '16px', height: '16px' }} src={mudTokenPng}></img> $MUD Add Liquidity</a></div><br />
    </div>)
}