import { h } from "preact";
import { useEffect, useState } from 'preact/hooks';
import styles from '../style.css'
import { Footer } from "./Footer";
import { ethers } from 'ethers'
import holders from '../holders.json'

export const Airdrop = (props) => {
  const { address } = props;

  return (
    <div id={styles["connected"]} className={styles["mint-container"]}>
    <b id={styles["mint-header"]}>Airdrop</b>
    <div style={{ marginTop: '20px' }}>Based on the snapshot, you qualify for...</div>
    <div style={{ marginTop: '20px' }}>{holders[ethers.utils.getAddress(address)]} NFTs</div>
    <Footer />
    </div>)
}