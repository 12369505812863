import { h, render } from "preact";
import { useState, useEffect } from 'preact/hooks';
import styles from './style.css'
import { ethers } from 'ethers'
import nftABI from './nft_abi.json'
import stakeABI from './stake_abi.json'
import ercABI from './erc_abi.json'
import { ercContractAddress, getTraits, nftContractAddress, stakingContractAddress } from "./Contracts";
import { fe, rMud } from "./DisplayHelpers";
import { getLargestBonusId } from "./ClayTraits";
import { Header } from "./components/Header";
import { Gallery } from "./components/Gallery";
import { History } from "./components/History";
import { Upgrades } from "./components/Upgrades"
import { Tabs } from "./components/Tabs/Tabs";
import { Tab } from "./components/Tabs/Tab";
import { Abilities } from "./components/Abilities";
import { Ranking } from "./components/Ranking";
import { Airdrop } from "./components/Airdrop";

const ethereum = (window as any).ethereum

const App = () => {
  const [nftContract, setNftContract] = useState(undefined)
  const [ercContract, setErcContract] = useState(undefined)
  const [stakingContract, setStakingContract] = useState(undefined)
  const [address, setAddress] = useState(undefined)
  const [connected, setConnected] = useState(0)
  const [activeTab, setActiveTab] = useState(0)
  const [tokens, setTokens] = useState([])
  const [mudBalance, setMudBalance] = useState('')
  const [totalMudWithdraw, setTotalMudWithdraw] = useState('')
  const [totalMudWithdrawWithBonus, setTotalMudWithdrawWithBonus] = useState('')
  const [clayTraits, setClayTraits] = useState([])

  const updateClayTraits = async id => {
    let traits = [...clayTraits]
    traits[id] = await getTraits(stakingContract, tokens[id])
    setClayTraits([...traits])
  }
  
  async function onConnect(signer) {
    const nftContract = new ethers.Contract(nftContractAddress, nftABI, signer)
    const ercContract = new ethers.Contract(ercContractAddress, ercABI, signer)
    const stakingContract = new ethers.Contract(stakingContractAddress, stakeABI, signer)
    const address = await signer.getAddress()
    setNftContract(nftContract)
    setErcContract(ercContract)
    setStakingContract(stakingContract)
    setAddress(address)
    setConnected(1)
    const tokens = await nftContract.tokensOfOwner(address, 0, 3333)
    setTokens([...tokens])
    setMudBalance(fe(await ercContract.balanceOf(address)))
    setTotalMudWithdraw(fe(await stakingContract.getWithdrawTotal(tokens)))

    const traits = await Promise.all(
      tokens.map(async (token) => {
        return await getTraits(stakingContract, token)
      })
    )
    setClayTraits([...traits])

    const largestBonusId = getLargestBonusId(traits)
    setTotalMudWithdrawWithBonus(fe(await stakingContract.getWithdrawTotalWithBonus(tokens, largestBonusId)))
  }  

  async function performConnect() {
    await ethereum.request({ method: 'eth_requestAccounts' })
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const accounts = await provider.listAccounts()
    if (accounts.length > 0) {
      onConnect(signer)
    } else {
      setConnected(1)
      setAddress(undefined)
    }
  }

  useEffect(() => {
    const onDocumentLoad = async () => {       
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const accounts = await provider.listAccounts()
        if (accounts.length > 0) {
          const signer = provider.getSigner()
          await onConnect(signer)
        } else {
          await performConnect()
        }
      }
    }

    onDocumentLoad()
  }, []);

  return (<div>
      <div className={styles.renderCanvas} />
      <div className={styles.cover}>
      <Header />
      <div className={styles["section"]} id={styles["mint"]}>
      <div className={styles["info-wrapper"]} style={{ marginTop: '20px' }}>
        <div className={styles["info-text"]}>Wallet Balance: {rMud(mudBalance)}</div><br/>
        <div className={styles["info-text"]}>You are holding {tokens?.length} CLAYS</div><br/>
      </div>
      {connected ? 
           (tokens.length > 0 ?
            (<Tabs activeTab={activeTab} renderInactive={false} onChangeTab={id => {setActiveTab(id)}} >
              <Tab title="HOME" active={true}>
                <Gallery
                  setMudBalance = {setMudBalance}
                  tokens = {tokens}
                  address = {address}
                  stakingContract = {stakingContract}
                  nftContract = {nftContract}
                  ercContract = {ercContract}
                  totalMudWithdraw = {totalMudWithdraw}
                  totalMudWithdrawWithBonus = {totalMudWithdrawWithBonus}
                  clayTraits = {clayTraits}
                />
              </Tab>
              <Tab title="UPGRADES" active={false}>
                <Upgrades
                  tokens = {tokens}
                  stakingContract = {stakingContract}
                  nftContract = {nftContract}
                  clayTraits = {clayTraits}
                  updateClayTraits = {updateClayTraits}
                  mudBalance={mudBalance}
                />
              </Tab>
              <Tab title="ABILITIES" active={false}>
                <Abilities
                  tokens = {tokens}
                  stakingContract = {stakingContract}
                  nftContract = {nftContract}
                  clayTraits = {clayTraits}
                  ercContract = {ercContract}
                  address = {address}
                  setMudBalance = {setMudBalance}
                  updateClayTraits = {updateClayTraits}
                />
              </Tab>
              <Tab title="RANKING" active={false}>
                <Ranking
                  stakingContract = {stakingContract}
                  nftContract = {nftContract}
                />
              </Tab>
              <Tab title="HISTORY" active={false}>
                <History
                  stakingContract = {stakingContract}
                  nftContract = {nftContract}
                />
              </Tab>
              <Tab title="AIRDROP" active={false}>
                <Airdrop
                  address = {address}
                />
              </Tab>
            </Tabs>) : (<div></div>)) :
          (<div id="disconnected" className={styles["mint-container"]}>
            <div>
              <div style={ {'margin-bottom': '32px'} }>
                <b id={styles["mint-header"]}>CLAY</b>
              </div>
              <div id="connect-message" style={ {'margin-bottom': '24px'} }>Connect to sculpt</div>
              <div className={styles["button"]} id={styles["connect-button"]} onClick={
                async () => {
                  await performConnect()
                }
              }>
                CONNECT
              </div>
            </div>
          </div>)}
        </div>

      </div>
    </div>);
}

render(<App />, document.getElementById("root")!);